.footer-bg {
  background-color: #ffffff;
}
.footer-link {
  display: flex;
  justify-content: flex-end;
}
.bottom-border {
  background: #cecece;
  height: 1px;
  width: 100%;
}
.smallfont {
  font-size: 16px;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .footer-link {
    display: flex;
    justify-content: flex-start !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
.footer-backgroundc{
  background-color: #0066dbfb;
  color: #ffffff;
}
.barbikan-link a{
  color: #ffffff !important;
  text-decoration: none;
}
.footer-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Ensure the container takes full height */
  min-height: 43px; /* You can set a minimum height if needed */
}

.bold p {
  margin: 0; /* Remove any margin on the <p> tags */
}
.barbikan-link a:hover{
  text-decoration: underline;
}