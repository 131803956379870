.image-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.image-container img {
    width: 100%; /* Ensure full width within the container */
    height: 250px; /* Fixed height for consistent layout */
    object-fit: cover; /* Maintain aspect ratio while filling container */
    transition: transform 0.8s ease-in-out; /* Smooth zoom effect only for the image */
}

.image-container img:hover {
    transform: scale(1.1); /* Zoom-in effect on image */
}
.text-center .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.text-center .btn-primary:hover {
    background-color: #0056b3;
}

.text-center .btn-secondary {
    background-color: #6c757d;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.text-center .btn-secondary:hover {
    background-color: #5a6268;
}
