/* servicebanner start */
.service-banner {
  background-image: url(../images/servicebanner.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-pad {
  padding: 250px 0;
}

.title {
  font-size: 50px;
  color: #fff;
}

.banner-description {
  font-size: 35px;
  color: #fff;
}

.quote-btn button {
  background-color: #1c6fd0;
  border: 1px solid #1c6fd0;
  color: #fff;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: "bold";
  margin: 20px 0;
}
/* servicebanner end */
/* maintenance start */
.inspect-box {
  padding: 20px;
  border-radius: 15px;
  background: #8bd4f32b;
}
.inspect-title {
  font-size: 25px;
  font-family: "bold";
  color: #1868c5;
  margin: 10px 0;
}
.inspct-description {
  font-family: "regular";
  font-size: 16px;
}
/* maintenance end */
/* spare start */
.Spares-title {
  display: inline-block;
  background-color: #e6f7ff !important;
  padding: 12px;
}

.Spares-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  transition: 1s;
}

.Spares-box:hover {
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 #3283e1, 0 6px 20px 0 #3283e1;
}

.Spares-box-image {
  height: 240px;
  width: 240px;
  display: flex;
}

.Spares-box-image img {
  object-fit: contain;
}
/* spares end */
/* others start */
.other-service-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  transition: 1s;
}
.other-service-img {
  width: 300px;
  height: 300px;
  display: flex;
  margin: 0 auto;
}
.other-service-img img {
  object-fit: cover;
  border-radius: 18px;
}
/* others end */
/* service agreement start */
.bg-agreement {
  background: rgb(57, 86, 214);
  background: linear-gradient(
    180deg,
    rgba(57, 86, 214, 1) 10%,
    rgba(0, 47, 132, 1) 83%
  );
}
.sa-details {
  font-family: "regular";
  color: #ffffff;
}
.sa-icon {
  background: #fff;
  color: #060694;
  font-size: 40px;
  display: inline-block;
  height: 65px;
  width: 65px;
  line-height: 46px;
  padding: 8px 12px;
  border-radius: 50px;
}
.sa-head {
  font-size: 28px;
  font-weight: bold;
}
.sa-para {
  font-size: 20px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .sa-head {
    font-size: 20px;
    font-weight: bold;
  }
  .sa-para {
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sa-head {
    font-size: 22px;
    font-weight: bold;
  }
  .sa-para {
    font-size: 18px;
  }
}