.title {
  text-transform: uppercase;
}
.impact-collab {
  position: relative;
}
.collab-box {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 12px;
}
.impact-description {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 18px;
  font-size: 20px;
}
.impact-banner {
  position: relative;
  top: -100px;
  z-index: -1;
}
.vision img{border-radius: 14px;}
