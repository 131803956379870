.contact-image {
  position: relative;
  overflow: hidden;
}

.contact_form {
  position: relative;
}
.contactus {
  background: rgba(195, 195, 195, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 30px;
  position: absolute;
  top: 150px;
  left: 30px;
}
.md-pic {
  width: 55px;
  height: 54px;
  display: flex;
  border-radius: 50%;
  margin-right: 20px;
}
.md-pic img {
  border-radius: 50%;
  object-fit: cover;
}
.md-details {
  display: flex;
  margin: 30px 0;
}
.md-icon {
  background-color: white;
  padding: 12px 15px;
  font-size: 24px;
  border-radius: 50%;
  height: 54px;
  width: 54px;
  line-height: 25px;
  margin-right: 20px;
}
.contact_form::after {
  content: "";
  position: absolute;
  background: url(../images/greeneffect.webp) no-repeat center / cover;
  height: 600px;
  width: 600px;
  top: 0px;
  right: 0px;
}
.contact-form {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 25px;
  position: relative;
  left: -100px;
}
.form-title {
  font-size: 38px;
}
textarea {
  resize: none;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .contact-form {
    position: relative;
    left: 0px;
  }
  .contact_form::after {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-form {
    position: relative;
    left: 0px;
  }
  .contact_form::after {
    display: none;
  }
}
