.login {
  background-color: #f66222;
  color: #f7f7f7;
  padding: 0px 6px;
  padding: 6px 24px;
  display: inline-block;
  border-radius: 8px;
  font-weight: bold;
}
.login svg {
  font-size: 20px;
}
.navfont {
  font-family: "bold";
  font-size: 20px;
  letter-spacing: 1px;
}
.navbar-light .navbar-nav .nav-link {
  color: #037c03 !important;
  padding: 0 25px;
  margin: 10px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #080488 !important;
  border-bottom: 3px solid #080488;
}
.navbar-light.navbar-nav a .active {
  color: #080488 !important;
  border-bottom: 2px solid #080488;
}
.active-link {
  color: #007bff; /* Adjust color as desired */
  font-weight: bold; /* Adjust other styles as desired */
  text-decoration: underline; /* Add underline if you want */
}
@media (min-width: 1px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
/* Modal Styling */
.modal-content {
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: none;
}

.modal-header {
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border-bottom: none;
  text-align: center;
  padding: 20px;
}

.modal-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin: 0 auto;
  text-transform: uppercase;
}

.modal-body {
  padding: 30px;
}

/* Button Styling */
.login-btn {
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.login-btn:hover {
  background: linear-gradient(45deg, #0056b3, #003f7f);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 91, 187, 0.4);
}

/* Container Styling */
.modal-container {
  text-align: center;
}

/* Column Spacing */
.py-3 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
  .modal-title {
    font-size: 1.5rem;
  }

  .login-btn {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}
