/* Home Banner start */
.home-banner {
  background-image: url(../images/homebanner.webp);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-title {
  font-size: 38px;
}
.ltrspce {
  letter-spacing: 7px;
}
.glass-morphi {
  background: rgba(91, 91, 93, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(46px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  display: inline-block;
  padding: 20px;
  width: 80%;
}
.morphi-title {
  font-size: 20px;
}
.save-earth {
  background-color: #e4e4e4e4;
  padding: 5px 24px 5px 5px;
  border: 2px solid #f7f7f7;
  border-radius: 50px;
  display: inline-block;
  margin: 40px auto;
}
.save-earth-title {
  font-size: 20px;
}
.globe {
  background-color: #0052b4;
  color: #f7f7f7;
  height: 35px;
  width: 35px;
  font-size: 23px;
  padding: 5px 6px;
  border-radius: 50px;
  display: inline-block;
  line-height: 24px;
  margin: 0 24px 0px 0px;
}
.bottom-content {
  position: relative;
}
.bottom-glass-morphi {
  background: rgba(119, 119, 119, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 15px;
  border: 2px solid #747474;
  padding: 18px;
  width: 310px;
  max-width: 350px;
  z-index: 33;
  position: relative;
  top: 250px;
}
.morphi-solar img {
  border-radius: 12px;
}
.banner-bottom {
  color: white;
  position: relative;
  left: 300px;
  top: -100px;
  display: inline-block;
  font-size: 20px;
  padding: 35px 100px 35px 30px;
  border-radius: 0 123px 0 0;
  z-index: 99;
  clip-path: inset(0 0 0 0 round 10px 10px 0 0);
  background: rgba(0, 102, 219, 0.747);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(116, 86, 86, 0.18);
  position: relative;
  top: 60px;
  z-index: 5;
}
/* Home Banner end */
/* overview start */
.over-view-container {
  position: relative;
  overflow: hidden;
}
.over-view-container::before {
  content: "";
  position: absolute;
  height: 240px;
  width: 380px;
  top: 60px;
  right: 0px;
  background: url(../images/cloudone.webp) no-repeat center / cover;
}
.over-view-container::after {
  content: "";
  position: absolute;
  height: 240px;
  width: 380px;
  top: -130px;
  right: -330px;
  background: url(../images/colorblur.webp) no-repeat center / cover;
  overflow: auto;
}
.overview-container {
  position: relative;
  overflow: hidden;
}
.overview-container::before {
  content: "";
  position: absolute;
  height: 220px;
  width: 400px;
  bottom: 130px;
  left: 20px;
  background: url(../images/cloudone.webp) no-repeat center / cover;
}
.overview-container::after {
  content: "";
  position: absolute;
  height: 240px;
  width: 380px;
  bottom: 0px;
  left: 38px;
  background: url(../images/colorblur.webp) no-repeat center / cover;
  overflow: auto;
}
.air-turbine img {
  border-radius: 15px;
}
.overview {
  position: relative;
}
.pre-profile {
  width: 255px;
  position: relative;
}
.overview-title {
  font-size: 45px;
  padding-bottom: 42px;
}
.air-box {
  background: rgba(30, 30, 30, 0.7);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
  position: absolute;
  z-index: 99;
  left: -32%;
  width: 165px;
  bottom: 30%;
}
.wind-mill {
  background-color: #fff;
  display: inline-flex;
  padding: 12px;
  border-radius: 50%;
  margin: 20px auto;
  font-size: 49px;
  height: 70px;
  width: 70px;
}
.over-view-para {
  font-size: 20px;
  font-family: "regular";
  padding-top: 200px;
}
/* overview end */
/* about start */
.navy-border {
  height: 3px;
  width: 62px;
  background-color: #0153b5;
}
.solution-title {
  font-size: 40px;
  padding-bottom: 55px 0;
}
.spin {
  position: relative;
}
.spin .wind img {
  border-radius: 22px;
  width: 80%;
}
.thunder-icon {
  position: absolute;
  top: 58%;
  left: -32px;
  width: 200px;
}
.grid-solution {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: inline-block;
  padding: 40px 22px;
  position: absolute;
  top: 45%;
  right: 0;
}
.grid-solution-icon {
  background-color: #0153b5;
  padding: 12px;
  font-size: 25px;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 30px;
}
.banner-about {
  transform: rotate(180deg);
  position: relative;
  top: -58%;
  z-index: -99;
}
.banner-card {
  position: relative;
}
.banner-about-para {
  position: absolute;
  top: -65px;
  font-family: "regular";
  font-size: 20px;
  color: white;
}
/* about end */
/* benefits start */
.overflow {
  overflow: hidden;
}
.service-page {
  position: relative;
}
.box {
  -webkit-box-shadow: 1px 1px 11px 9px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 11px 9px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: inline-block;
  box-shadow: 1px 1px 11px 9px rgba(0, 0, 0, 0.1);
}
.box-title {
  font-size: 22px;
}
.box-bg {
  background-color: #fff;
  border-radius: 18px 18px 0 18px;
  position: absolute;
  top: 0px;
  right: 110px;
}
.box-blu {
  background: #0066dbfb;
  border-radius: 0px 18px 18px 0px;
  position: absolute;
  top: 202px;
  right: -65px;
  color: white;
}
.box-blue {
  background: #021d54;
  border-radius: 18px 0px 18px 18px;
  position: absolute;
  top: 400px;
  right: 110px;
  color: white;
}
.icon-box {
  display: inline-block;
  font-size: 46px;
  height: 65px;
  width: 65px;
  max-height: 100%;
  text-align: center;
  line-height: 52px;
  border-radius: 50% 50% 50% 0%;
  margin-bottom: 20px;
}
.thunder-bg {
  background-color: #0052b4;
}
.sunny-bg {
  background-color: white;
}
.sunny-bg svg {
  color: #0052b4;
}

.para-title {
  color: #36ba6f;
  text-transform: uppercase;
  font-family: "regular";
  font-weight: 600;
  font-size: 30px;
}
.green-border {
  height: 3px;
  width: 50px;
  background-color: #36ba6f;
}
.service-para .sub-title {
  font-size: 38px;
  font-family: "regular";
  font-weight: 600;
}
.service-text {
  font-size: 18px;
  font-family: "regular";
}
.turbine-paralax {
  background: url(../images/paralax.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
/* benefits end*/
/* section start */
.section {
  position: relative;
}
.section::after {
  content: "";
  position: absolute;
  background: url(../images/cloudone.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 220px;
  width: 400px;
  bottom: 10px;
  left: 20px;
  z-index: -1;
}
.section-glass {
  background: rgba(87, 87, 87, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 35px;
  display: inline-block;
  position: relative;
  left: 25px;
  top: 150px;
  color: #fff;
}
.sec-desk-title {
  font-size: 30px;
  font-family: "regular";
  padding-bottom: 15px;
}
.sec-descript {
  font-size: 22px;
  font-family: "regular";
}
/* section end */
/* demands start */
.rounded {
  border-radius: 18px !important;
}
.demand {
  position: relative;
}

.square-panel {
  background: rgba(255, 255, 255, 0.515);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
  display: flex;
  margin-bottom: 12px;
  position: absolute;
  top: 230px;
  right: -270px;
  font-size: 20px;
  font-family: "regular";
}
.square-panel-one {
  background: rgba(255, 255, 255, 0.515);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
  display: flex;
  margin-bottom: 12px;
  position: absolute;
  top: 340px;
  right: -270px;
  font-size: 20px;
  font-family: "regular";
}
.numb-panel {
  background: #36ba6f;
  padding: 8px 12px;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 30px;
  font-size: 18px;
  color: #fff;
  font-family: "regular";
  margin-right: 1rem;
}
/* demands end */
/* contact start */

/* contact end */
/* media query start mobile */
@media (min-width: 1px) and (max-width: 767.98px) {
  /* Home Banner start */
  .banner-title {
    font-size: 20px;
  }
  .home-banner {
    background-size: contain;
  }
  .glass-morphi {
    width: 100%;
  }
  .bottom-glass-morphi {
    top: 0px;
    width: 100%;
    margin: 0 auto;
  }
  .banner-bottom {
    border-radius: 0;
    top: 20px !important;
    left: 0;
    border-radius: 12px;
  }

  .box-title {
    font-size: 18px !important;
  }
  /* Home Banner end */
  /* overview start */
  .overview-title {
    font-size: 30px !important;
    padding-bottom: 42px;
  }
  .over-view-container::before,
  .over-view-container::after,
  .section::after,
  .overview-container::before,
  .overview-container::after {
    display: none !important;
  }
  .air-box {
    left: 50% !important;
  }
  .over-view-para {
    padding-top: 0px !important;
  }
  /* overview end */
  /* about start */
  .solution-title {
    font-size: 24px !important;
  }
  .spin .wind img {
    width: 100% !important;
  }
  .banner-about {
    display: none;
  }
  .banner-about-para {
    color: black !important;
  }
  /* about end */
  /* benefits start */
  .banner-about-para {
    top: 0px !important;
    color: #2c2c2c !important;
    position: relative !important;
    padding: 20px 0;
    font-size: 16px !important;
  }
  .box-bg,
  .box-blue,
  .box-blu {
    width: 100% !important;
    text-align: center;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    border-radius: 0px !important;
  }
  .sub-title {
    font-size: 28px !important;
  }
  /* benefits end*/
  /* section start */
  .section::after {
    bottom: -40px !important;
    left: -40px !important;
  }
  .section-glass {
    top: 0px !important;
    left: 0 !important;
  }
  /* section end */
  /* demands start */
  .square-panel {
    position: relative !important;
    top: 0px !important;
    right: 0px !important;
    font-size: 18px !important;
  }
  .square-panel {
    position: relative !important;
    top: 0px !important;
    right: 0px !important;
    font-size: 18px !important;
  }
  .square-panel-one {
    position: relative !important;
    top: 0px !important;
    right: 0px !important;
    font-size: 18px !important;
  }
  .sub-title {
    font-size: 28px !important;
  }
  /* demands end */
  /* contact start */
  .hybrid-model {
    font-size: 23px;
  }
  /* contact end */
}

/* media query end mobile */
/* media query start tab,large */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Home Banner start */
  .banner-title {
    font-size: 20px;
  }
  .home-banner {
    background-size: contain;
  }
  .glass-morphi {
    width: 100%;
  }
  .bottom-glass-morphi {
    top: 0px;
    width: 100%;
    margin: 0 auto;
  }
  .banner-bottom {
    border-radius: 0;
    top: 20px !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 12px;
  }
  /* Home Banner end */
  /* overview start */
  .over-view-container::before,
  .over-view-container::after,
  .section::after,
  .overview-container::before,
  .overview-container::after {
    display: none !important;
  }
  .overview-title {
    font-size: 30px !important;
    padding-bottom: 42px;
  }
  .air-box {
    left: 150% !important;
  }
  .over-view-para {
    padding-top: 0px !important;
  }

  /* overview end */
  /* about start */
  .solution-title {
    font-size: 24px !important;
  }
  .spin .wind img {
    width: 100% !important;
  }
  .banner-about {
    display: none;
  }
  .banner-about-para {
    color: black !important;
  }
  .banner-about-para {
    color: #2c2c2c !important;
    position: relative !important;
    padding: 20px 0;
    font-size: 19px !important;
  }
  .thunder-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 138px;
  }
  /* about end */

}
/* media query end tab,large */
