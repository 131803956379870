/* abtbanner start */
.abt-title {
  font-size: 38px;
}
.abt-banner {
  width: 402px;
  max-width: 402px;
}
.abt-banner-image img {
  border-radius: 14px;
}
.abt-banner img {
  width: 100%;
  border-radius: 14px;
}
/* abtbanner end */
/* counter start */
.counter-font {
  font-size: 35px;
}
.long-banner {
  position: relative;
}
.long-banner img {
  border-radius: 34px;
}
.long-banner-content {
  background: rgba(162, 160, 160, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: inline-block;
  width: 50%;
  padding: 30px;
  position: absolute;
  top: -40px;
  left: 20px;
}
/* counter end */
/* expertise start */
.solar-cap img {
  border-radius: 16px;
}
.pos {
  position: relative;
}
.box-bg-one {
  background-color: #fff;
  border-radius: 18px 18px 0 18px;
  position: absolute;
  top: 30px;
  left: 15%;
}
.box-blu-one {
  background: #0066dbfb;
  border-radius: 0px 18px 18px 0px;
  position: absolute;
  top: 202px;
  left: 50%;
  color: white;
}
.box-blue-one {
  background: #021d54;
  border-radius: 18px 0px 18px 18px;
  position: absolute;
  top: 350px;
  left: 18%;
  color: white;
}
.mockup-wind {
  position: absolute;
  top: -276px;
  left: 30%;
  z-index: -1;
}
/* expertise end */
/* what we start */
.what-we {
    position: relative;
  }
  .what-we img {
    border-radius: 0 54px 54px 0;
  }
  .what-we-morphi {
    background: rgba(161, 161, 161, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: absolute;
    display: inline-block;
    padding: 20px;
    bottom: 20px;
    right: 0;
  }
  .what-we-morphi svg {
    font-size: 45px;
    background: #0052b4;
    border-radius: 50%;
    color: white; margin: 20px auto;
  }
  /* what we end */
/* media query start mobile */
@media (min-width: 1px) and (max-width: 767.98px) {
  /* abtbanner start */
  .abt-banner {
    width: 100%;
  }
  /* abtbanner end */
  /* counter start */
  .long-banner-content {
    position: relative !important;
    width: 100% !important;
    left: 0 !important;
  }
  /* counter end */
  /* expertise start */
  .box-bg-one {
    position: relative;
    width: 100%;
    left: 0;
    text-align: center;
    border-radius: 18px !important;
  }
  .box-blu-one {
    position: relative;
    left: 0;
    width: 100%;
    top: 44px;
    text-align: center;
    border-radius: 18px !important;
  }
  .box-blue-one {
    position: relative;
    top: 60px;
    left: 0 !important;
    width: 100%;
    text-align: center;
    border-radius: 18px !important;
  }
  .mockup-wind {
    display: none;
  }
  .collab-box {
    width: 100%;
    margin: 12px 0;
  }
  /* expertise end */
}
/* media query end mobile */
/* media query start tab,large */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* abtbanner start */
  .abt-banner {
    width: 100% !important;
  }
  /* abtbanner end */
  /* what we start */
  .what-we-morphi {
    left: 50%;
    text-align: center;
  }
  /* what we end */
  /* expertise start */
  .box-bg-one {
    position: relative;
    width: 33.3%;
    left: 0;
    text-align: center;
    top: 10px;
    border-radius: 18px !important;
  }
  .box-blu-one {
    position: relative;
    left: 0;
    width: 33.3%;
    top: 10px;
    text-align: center;
    border-radius: 18px !important;
  }
  .box-blue-one {
    position: relative;
    top: 10px;
    left: 0 !important;
    width: 33.3%;
    text-align: center;
    border-radius: 18px !important;
  }
  .mockup-wind {
    display: none;
  }
  .collab-box {
    width: 100%;
    margin: 12px 0;
  }
  .impact-banner {
    display: none;
  }
  /* expertise end */
}

/* media query end tab,large */
