@font-face {
  font-family: "regular";
  src: url("fonts/Rajdhani-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "medium";
  src: url("./fonts//Rajdhani-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "bold";
  src: url("./fonts//Rajdhani-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
body {
  overflow-x: hidden;
  background: #f3f3f3 !important;
}
.medium {
  font-family: "regular";
}
.regular {
  font-family: medium;
}
.bold {
  font-family: "bold";
}
.logo {
  width: 280px;
}
.padding {
  padding: 44px 0;
}
.pad {
  padding: 150px 0;
}
.bg-overlay {
  position: relative;
}
.bg-overlay::after {
  background-color: rgba(0, 0, 0, 0.141);
  z-index: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.white {
  position: relative;
  z-index: 88;
}
.wordbreak {
  white-space: pre;
}
.largefont {
  font-size: 5rem;
}
.mediumfont {
  font-size: 2rem;
}
.md-font {
  font-size: 2.5rem;
}
.navy {
  color: #002f84;
}
.fw-600 {
  font-weight: 600;
}
@keyframes bgAnimation {
  0% {
    -webkit-transform: scale(0.9);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1.2);
    opacity: 1;
  }
}

.bg-loader {
  background: #ffffff url(./images/logo.png) no-repeat center center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  animation: bgAnimation 10s infinite; /* Adjust duration and other animation properties as needed */
  transition: 1s all;
}
.home .btn{
  background: none !important;
  color: #062676 !important;
  border: 1px solid #062676 !important;
  border-radius: 5pc;

}
@media (min-width: 1px) and (max-width: 767.98px) {
  .pad {
    padding: 40px 0;
  }
  .padding {
    padding: 22px 0;
  }
  body {
    overflow-x: hidden !important;
  }
  .largefont {
    font-size: 2rem;
  }
  .mediumfont {
    font-size: 18px;
  }
  .bg-loader {
   background-size: contain;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .largefont {
    font-size: 2rem;
  }
  .mediumfont {
    font-size: 18px;
  }
  .pad {
    padding: 40px 0;
  }
  .padding {
    padding: 22px 0;
  }
  .footer-link {
    justify-content: flex-start !important;
  }
}
